import "../../scss/Pagination.scss";
import { useContext } from "react";
import { SearchContext } from "../searchContext";

export const Pagination = ({ fetchProducts }) => {

    const searchContext = useContext(SearchContext);
    const appState = searchContext.appState;

    const totalPages = Math.ceil(appState.totalCount / appState.pageSize);
    let currentPage = appState.currentPage;

    const pageNumbers = [];
    let startPage, endPage;

    if (totalPages <= 4) {
        startPage = 1;
        endPage = totalPages;
    } else {
        // Calculate start and end pages for the current page
        if (currentPage <= 3) {
            startPage = 1;
            endPage = 4;
        } else if (currentPage + 2 >= totalPages) {
            startPage = totalPages - 3;
            endPage = totalPages;
        } else {
            startPage = currentPage - 2;
            endPage = currentPage + 1;
        }

        // Ensure endPage does not exceed totalPages
        endPage = Math.min(endPage, totalPages);
    }

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="container pb-4">
            <div className="pagination-container">
                <div className="text-start">
                    {
                        // disable this button on mobile screen
                        appState.pageSize !== 6 &&
                        <button
                            className={`pagination-button ${currentPage === 1 ? 'disabled-navigation-button' : ''}`}
                            onClick={() => fetchProducts(1, appState?.activeIngredient, appState?.supportedState, appState?.sortOrder)}
                            disabled={currentPage === 1}
                        >
                            &lt;&lt;
                        </button>
                    }
                    <button
                        className={`pagination-button ${currentPage === 1 ? 'disabled-navigation-button' : ''}`}
                        onClick={() => fetchProducts((currentPage - 1), appState?.activeIngredient, appState?.supportedState, appState?.sortOrder)}
                        disabled={currentPage === 1}
                    >
                        &lt;
                    </button>
                </div>
                <div className="text-center">
                    {pageNumbers.map(number => (
                        <button
                            key={number}
                            className={`pagination-button ${currentPage === number ? 'active' : ''}`}
                            onClick={() => fetchProducts(number, appState?.activeIngredient, appState?.supportedState, appState?.sortOrder)}
                        >
                            {number}
                        </button>
                    ))}
                </div>
                <div className="text-end">
                    <button
                        className={`pagination-button ${currentPage === totalPages ? 'disabled-navigation-button' : ''}`}
                        onClick={() => fetchProducts((currentPage + 1), appState?.activeIngredient, appState?.supportedState, appState?.sortOrder)}
                        disabled={currentPage === totalPages}
                    >
                        &gt;
                    </button>
                    {
                        appState.pageSize !== 6 &&
                        <button
                            className={`pagination-button ${currentPage === totalPages ? 'disabled-navigation-button' : ''}`}
                            onClick={() => fetchProducts(totalPages, appState?.activeIngredient, appState?.supportedState, appState?.sortOrder)}
                            disabled={currentPage === totalPages}
                        >
                            &gt;&gt;
                        </button>
                    }
                </div>
            </div>
        </div>
    );
};
