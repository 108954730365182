import React from 'react';

const ByNcSa = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 403.000000 141.000000"
    preserveAspectRatio="xMidYMid meet"
    className='by-nc-sa-image'
  >
    <g transform="translate(0.000000,141.000000) scale(0.100000,-0.100000)"
       fill="#000000" stroke="none">
      <path d="M24 1385 l-24 -24 2 -678 3 -678 2010 0 2010 0 3 678 2 678 -24 24
        -25 25 -1966 0 -1966 0 -25 -25z m3964 -492 l2 -473 -1385 0 -1385 0 -17 -27
        c-83 -131 -224 -230 -374 -263 -81 -18 -247 -8 -325 19 -111 39 -227 129 -296
        228 l-29 43 -69 0 -70 0 0 468 c0 258 3 472 7 475 3 4 891 6 1972 5 l1966 -3
        3 -472z m-1233 -587 c29 -12 55 -41 55 -60 0 -13 -48 -5 -54 9 -7 20 -60 19
        -80 -1 -18 -18 -21 -76 -6 -105 15 -27 75 -26 90 1 11 21 50 28 50 9 0 -47
        -66 -84 -124 -68 -97 27 -100 190 -4 218 18 5 34 10 36 10 1 1 18 -5 37 -13z
        m653 -12 c27 -17 30 -54 4 -54 -10 0 -25 9 -35 20 -18 22 -34 25 -56 11 -23
        -15 -5 -39 34 -47 96 -18 117 -109 30 -133 -58 -16 -125 11 -125 50 0 25 31
        25 53 -1 26 -30 77 -24 77 10 0 14 -14 23 -55 35 -42 12 -58 22 -65 40 -27 71
        63 116 138 69z m-1734 3 c20 -15 25 -69 7 -75 -7 -2 -4 -13 9 -29 26 -33 25
        -49 -5 -78 -21 -22 -33 -25 -95 -25 l-70 0 0 110 0 110 68 0 c41 0 75 -5 86
        -13z m121 -27 c14 -22 27 -40 29 -40 3 0 15 18 27 40 18 32 28 40 51 40 l28 0
        -40 -65 c-31 -51 -40 -75 -40 -110 0 -41 -2 -45 -25 -45 -23 0 -25 4 -25 43 0
        23 -6 51 -13 62 -8 11 -26 41 -41 68 l-28 47 26 0 c19 0 32 -10 51 -40z m686
        -32 l44 -73 3 73 c3 64 5 72 22 72 19 0 20 -7 20 -110 0 -108 0 -110 -23 -110
        -17 0 -33 17 -67 73 l-45 72 -3 -72 c-3 -65 -5 -73 -22 -73 -19 0 -20 7 -20
        110 0 109 0 110 24 110 19 0 33 -15 67 -72z m1134 -18 c51 -134 50 -130 21
        -130 -19 0 -27 7 -32 25 -5 22 -12 25 -49 25 -37 0 -44 -3 -49 -25 -5 -19 -13
        -25 -32 -25 -21 0 -25 4 -20 17 70 189 78 204 104 201 21 -3 29 -15 57 -88z"/>
      <path d="M1650 1287 c-139 -33 -250 -140 -289 -279 -21 -73 -11 -191 21 -261
        35 -74 121 -160 195 -195 52 -24 70 -27 163 -27 95 0 110 3 164 29 160 78 247
        246 218 418 -16 92 -52 157 -124 223 -92 86 -224 120 -348 92z m186 -78 c98
        -25 194 -135 213 -244 25 -144 -43 -278 -177 -346 -68 -35 -196 -35 -267 0
        -194 96 -245 343 -102 498 90 98 194 126 333 92z"/>
      <path d="M1706 1147 c-38 -28 -13 -97 35 -97 47 0 65 67 27 94 -27 19 -39 20
        -62 3z"/>
      <path d="M1641 1016 c-8 -9 -11 -42 -9 -93 3 -72 5 -78 26 -81 21 -3 22 -8 22
        -93 l0 -89 60 0 60 0 0 90 c0 84 1 90 20 90 20 0 21 5 18 93 l-3 92 -91 3
        c-70 2 -94 -1 -103 -12z"/>
      <path d="M2532 1290 c-104 -22 -216 -109 -266 -207 -51 -103 -53 -240 -4 -342
        12 -25 44 -70 71 -98 87 -93 175 -128 304 -121 185 8 336 145 361 326 24 175
        -57 332 -211 406 -79 39 -176 52 -255 36z m215 -95 c113 -51 183 -164 183
        -297 0 -62 -2 -70 -17 -66 -10 3 -89 36 -175 74 -111 48 -158 74 -158 85 0 23
        40 31 80 18 29 -10 34 -9 57 14 l26 25 -31 16 c-17 9 -40 16 -52 16 -16 0 -20
        6 -20 30 0 23 -4 30 -20 30 -15 0 -20 -7 -20 -25 0 -19 -8 -28 -37 -40 -21 -9
        -45 -25 -54 -37 l-17 -21 -66 28 c-37 16 -69 32 -71 36 -8 12 72 87 116 110
        84 42 185 44 276 4z m-262 -261 c93 -41 173 -80 177 -87 3 -6 2 -19 -3 -29
        -12 -23 -70 -24 -103 -3 -21 14 -24 13 -50 -14 l-27 -29 43 -21 c25 -11 52
        -21 61 -21 12 0 17 -9 17 -30 0 -23 4 -30 20 -30 15 0 20 7 20 28 0 24 6 31
        40 45 24 9 44 26 51 41 7 14 17 26 23 26 21 0 136 -54 136 -64 0 -24 -110
        -114 -164 -135 -195 -73 -411 63 -424 266 -4 54 3 133 12 133 1 0 78 -34 171
        -76z"/>
      <path d="M3413 1289 c-101 -19 -206 -95 -260 -186 -100 -171 -53 -390 110
        -510 78 -57 146 -76 250 -71 144 6 265 86 329 216 31 62 33 74 33 167 0 91 -3
        106 -31 165 -55 117 -157 197 -279 219 -66 12 -92 12 -152 0z m152 -74 c82
        -13 176 -91 217 -180 31 -69 32 -190 1 -257 -27 -58 -97 -128 -160 -160 -69
        -34 -198 -33 -265 1 -65 33 -126 96 -157 161 -35 75 -36 183 -3 252 32 66 92
        129 146 156 48 24 125 41 161 36 11 -1 38 -6 60 -9z"/>
      <path d="M3415 1096 c-39 -17 -81 -67 -90 -106 -4 -19 -13 -30 -23 -30 -12 0
        -6 -11 20 -37 l38 -37 33 32 c25 24 33 40 30 56 -9 47 76 74 124 41 47 -33 54
        -156 12 -209 -16 -21 -29 -26 -62 -26 -51 0 -63 7 -76 43 -9 24 -16 27 -56 27
        -40 0 -45 -2 -45 -22 0 -31 33 -83 65 -104 40 -26 102 -34 159 -19 83 22 146
        106 146 196 0 47 -29 125 -57 151 -56 54 -151 73 -218 44z"/>
      <path d="M591 1269 c-122 -21 -262 -121 -340 -244 -72 -114 -94 -290 -51 -426
        50 -164 203 -311 365 -354 304 -79 617 127 655 430 19 151 -14 285 -97 396
        -123 163 -313 234 -532 198z m290 -126 c73 -34 167 -119 198 -181 90 -178 61
        -376 -77 -511 -174 -169 -440 -165 -607 10 -187 195 -153 496 74 651 77 53
        132 68 246 65 88 -2 108 -6 166 -34z"/>
      <path d="M505 905 c-49 -17 -81 -53 -95 -105 -29 -107 44 -210 150 -210 39 0
        106 34 120 61 13 23 14 22 -18 38 -19 10 -27 9 -49 -8 -14 -12 -36 -21 -49
        -21 -60 0 -88 117 -39 165 30 30 45 31 79 4 24 -18 30 -20 56 -9 34 14 34 14
        20 41 -24 43 -113 66 -175 44z"/>
      <path d="M819 910 c-71 -21 -109 -75 -109 -154 0 -114 78 -182 185 -162 47 9
        110 63 94 82 -20 24 -48 26 -75 5 -35 -27 -59 -26 -89 4 -31 30 -35 101 -7
        133 27 33 66 38 92 12 22 -22 24 -22 58 -6 l35 17 -28 29 c-15 15 -46 33 -70
        39 -49 12 -50 12 -86 1z"/>
      <path d="M1570 245 c0 -21 5 -25 28 -25 36 0 56 14 49 34 -4 10 -19 16 -42 16
        -31 0 -35 -3 -35 -25z"/>
      <path d="M1570 160 c0 -28 2 -30 40 -30 28 0 43 5 47 16 8 22 -22 44 -59 44
        -24 0 -28 -4 -28 -30z"/>
      <path d="M3542 221 c-17 -47 -15 -51 13 -51 28 0 30 11 11 55 l-12 30 -12 -34z"/>
    </g>
  </svg>
);

export default ByNcSa;
