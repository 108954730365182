import axios from "axios";
import { stringInterpolator } from "./utilities";

/**
 * Dataservice to get data from API
 */
export const dataServices = {
    /**
     * Get initial data(languages, occupation, crop, pest and search result) from API
     *
     * @param {string} [country] Country code
     * @param {string} [language] Language code
     * @param {string} [crop] Crop ID
     * @param {string} [pest]  Pest ID
     * @param {string} [filter] filter(crop,pest) on which crop and pest list to filter, if value is 'crop', then pest list filter by crop and vice-versa
     * @param {object} [settings] Settings value
     */
    getInitialData: async (country, language, crop, pest, filter, settings, stateCode="", pageSize = 0, pageNo = 0, filterData = "", sortDirection = "", sortBy = "") => {
        if(settings?.is_pagination){
            pageSize = (window.innerWidth > 768) ? settings?.page_size_for_desktop: settings?.page_size_for_mobile
        }
        else{
            pageSize = pageNo = 0;
        }
        const apiUrl = stringInterpolator(
            `${settings.api_url}/${settings.initialdata_endpoint}`,
            {
                country: country,
                language: language,
                crop: crop ?? "",
                pest: pest ?? "",
                filter: filter ?? "",
                stateCode: stateCode,
                includeProducts: crop !== null || pest !== null ? "true" : "false",
                pageSize: pageSize,
                pageNo: pageNo,
                filterData: filterData ?? "",
                sortBy: sortBy ?? "",
                sortDirection: sortDirection ?? ""
            }
        );

        return await axios.get(apiUrl);
    },

    getCrop: async (country
        , language
        , pest
        , settings
        , search = ""
        , productId = ""
        , stateCode = ""
        , cancelToken = null
        , notShowProgress = false) => {
        const apiUrl = stringInterpolator(
            `${settings.api_url}/${settings.crops_endpoint}`,
            { country: country, language: language, pest: pest, search: search, productId: productId, stateCode: stateCode }
        );
        return await axios.get(apiUrl, { notShowProgress: notShowProgress, cancelToken: cancelToken });
    },

    getPest: async (country
        , language
        , crop
        , settings
        , search = ""
        , productId = ""
        , stateCode = ""
        , cancelToken = null
        , notShowProgress = false) => {
        const apiUrl = stringInterpolator(
            `${settings.api_url}/${settings.pests_endpoint}`,
            { country: country, language: language, crop: crop, search: search, productId: productId, stateCode: stateCode }
        );

        return await axios.get(apiUrl, { notShowProgress: notShowProgress, cancelToken: cancelToken });
    },

    getCropsPests: async (country, language, productId, crop, pest, stateCode, page, sort, settings, notShowProgress = true) => {
        const apiUrl = stringInterpolator(
            `${settings.api_url}/${settings.crops_pests_endpoint}`,
            { country: country, language: language, productId: productId, crop: crop, pest: pest, size: settings?.page_size, page: page, sort: sort, stateCode: stateCode }
        );

        return await axios.get(apiUrl, { notShowProgress: notShowProgress });
    },

    getLanguages: async (country, settings) => {
        const apiUrl = stringInterpolator(
            `${settings.api_url}/${settings.language_endpoint}`,
            { country: country }
        );

        return await axios.get(apiUrl);
    },

    getProducts: async (country, language, crop, pest, stateCode, pageSize, pageNo, settings, filter="", sortDirection = "", sortBy = "") => {
        if(settings?.is_pagination){
            pageSize = (window.innerWidth > 768) ? settings?.page_size_for_desktop: settings?.page_size_for_mobile
        }
        else{
            pageSize = pageNo = 0;
        }
        const apiUrl = stringInterpolator(
            `${settings.api_url}/${settings.search_endpoint}`,
            {
                country: country,
                language: language,
                crop: crop,
                pest: pest,
                stateCode: stateCode,
                pageSize: pageSize,
                pageNo: pageNo,
                filter: filter,
                sortBy: sortBy,
                sortDirection: sortDirection
            }
        );

        return await axios.get(apiUrl);
    },

    getProduct: async (country, language, id, stateCode = '', settings) => {
        const apiUrl = stringInterpolator(
            `${settings.api_url}/${settings.productdetails_endpoint}`,
            {
                country: country,
                language: language,
                productId: id,
                stateCode: stateCode
            }
        );

        return await axios.get(apiUrl);
    },
};
